<template>
	<div id="id-summary" v-if="idCheckSummary && Object.keys(idCheckSummary).length > 0">
		<h2>
			{{ languageStrings.identificationStatus }}:
			<span :class="idCheckSummary?.status === 'Success' ? 'warn' : ''">{{
				idCheckSummary?.status === "Success" ? "Id Check Complete" : camelToTitleCase(idCheckSummary?.status)
			}}</span>
		</h2>
		<p class="center-text">
			{{ languageStrings.passedGeneralApproval }} <b v-if="!idCheckSummary.passedGeneralApproval">{{ languageStrings.false }}</b><b v-else> {{ languageStrings.true }}</b><br />{{
				languageStrings.passedAddressCheck
			}}
			<b v-if="!idCheckSummary.passedAddressCheck">{{ languageStrings.false }}</b><b v-else> {{ languageStrings.true }}</b><br />
			{{ languageStrings.passedIdDocumentCheck }}<b v-if="!idCheckSummary.passedIdDocumentCheck">{{languageStrings.false }}</b><b v-else> {{ languageStrings.true }}</b>
		</p>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";

export default {
	name: "IdCheckSummary",
	props: {
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		playerState: Object,
		isMobile: Boolean,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			idCheckSummary: {},
		};
	},
	watch: {},
	created() {
		this.eventBus.on("updateIdCheckSummary", () => {
			this.getIdCheckSummary();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("updateIdCheckSummary");
		});
	},
	mounted() {
		this.getIdCheckSummary();
	},
	methods: {
		async getIdCheckSummary() {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/idcheck/summary`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			params.set("userId", this.playerState?.userId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();
				dataJson = this.checkForLanguageStringStatus(dataJson);
				this.idCheckSummary = dataJson;
				let _state = this.playerState;
				_state.idCheckSummary = dataJson;
				this.eventBus.emit("updatePlayerState", _state);
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
		checkForLanguageStringStatus(dataJson) {
			if (dataJson?.status == "Unresolved") {
				dataJson.status = this.languageStrings.unresolved;
			}

			if (dataJson?.status == "NotNeeded") {
				dataJson.status = this.languageStrings.notNeeded;
			}

			if (dataJson?.status == "NotPerformed") {
				dataJson.status = this.languageStrings.notPerformed;
			}

			if (dataJson?.status == "Success") {
				dataJson.status = this.languageStrings.success;
			}

			if (dataJson?.status == "Failure") {
				dataJson.status = this.languageStrings.failure;
			}

			return dataJson;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.center-text {
	text-align: center;
}

@media (max-width:767px) {
h2 {
	margin-bottom: 3px;
}

p {
	margin-top: 5px;
	padding: 0px 30px;
}

}

</style>
